import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import s from './styles.module.css'

function LinkExternal({ link, label, className }) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(s.container, className)}
    >
      {label}
    </a>
  )
}

LinkExternal.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
}
LinkExternal.defaultProps = {
  link: '',
  label: '',
  className: '',
}

export default LinkExternal
