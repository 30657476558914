import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import classNames from 'classnames'

// import { INSTAGRAM_URL, FACEBOOK_URL } from 'constants'

import LinkExternal from 'components/LinkExternal'

import s from './styles.module.css'

const MenuLinks = ({ className, linkClassName }) => {
  const linkClass = {
    className: classNames(s.link, linkClassName),
  }
  return (
    <>
      <div className={classNames(s.container, className)}>
        <Link to="/" {...linkClass}>
          Home
        </Link>
        <Link to="/about" {...linkClass}>
          About
        </Link>
        <Link to="/our-collection" {...linkClass}>
          Collection
        </Link>
        <Link to="/boutiques" {...linkClass}>
          Boutiques
        </Link>
      </div>
      <Link to="/wholesale" {...linkClass}>
        Wholesale
      </Link>
      <Link to="/faqs" {...linkClass}>
        FAQs
      </Link>
      <Link to="/contact" {...linkClass}>
        Contact
      </Link>
      <Link to="/legal" {...linkClass}>
        Legal
      </Link>
      {/* <LinkExternal link={INSTAGRAM_URL} label="Instagram" {...linkClass} /> */}
      <LinkExternal
        link="https://www.instagram.com/chalkuk"
        label="Instagram"
        {...linkClass}
      />
      {/* <LinkExternal link={FACEBOOK_URL} label="Facebook" {...linkClass} /> */}
      <LinkExternal
        link="https://www.facebook.com/Chalk-574040983050940"
        label="Facebook"
        {...linkClass}
      />
    </>
  )
}

MenuLinks.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string,
}
MenuLinks.defaultProps = {
  className: '',
  linkClassName: '',
}

export default MenuLinks
